/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  CircularProgress,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FiEdit, FiMenu } from 'react-icons/fi';
import { IoMdPersonAdd } from 'react-icons/io';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { shopAndSmileAdapter } from '../api/backend';
import { db } from '../api/local';
import { SelectPosDevice } from '../components/auth/select-pos-device';
import { Sidebar } from '../components/cart/side-bar';
import {
  ColumnButton,
  ColumnHeader,
  ColumnHeading,
  ColumnIconButton,
} from '../components/column';
import CreateCustomerOverlay from '../components/customers/create-customer-overlay';
import CustomerOverlay from '../components/customers/customer-overlay';
import { MainHeader } from '../components/navigation/main-header';
import { Navbar } from '../components/navigation/nav-bar';
import { useCart } from '../hooks/use-cart';
import {
  activeLoyaltyProgramsAtom,
  currentCartIdAtom,
  discountsAtom,
  merchantAtom,
  offlineCustomersAtom,
  offlineReceiptsAtom,
  onlineReceiptsAtom,
  paymentTypesAtom,
  posDeviceAtom,
  shouldSignOutAtom,
  storeAtom,
  taxesAtom,
  triggerRefetchAtom,
  userAtom,
  workerActionsAtom,
} from '../recoil/atoms';
import { envVars } from '../utils/constants';
import { useAuth, useSingleTabRestriction } from '@bofrak-backend/shared-ui';
import { useNetworkState } from '@uidotdev/usehooks';
import { WebWorkerActions } from '@bofrak-backend/shared';
import ServiceWorkerUpdatePopup from '../components/service-worker-popup';
import useWebSocketStoreProducts from '../hooks/use-store-products-websocket';

export default function Root() {
  useSingleTabRestriction();
  useWebSocketStoreProducts(envVars.PRODUCTS_WEBSOCKET_API_URL);
  const {
    isOpen: isNavOpen,
    onOpen: onOpenNav,
    onClose: onCloseNav,
  } = useDisclosure();

  const {
    isOpen: isCartOpen,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();

  const {
    isOpen: isCustomerOverlayOpen,
    onOpen: onOpenCustomerOverlay,
    onClose: onCloseCustomerOverlay,
  } = useDisclosure();

  const {
    isOpen: isCreateCustomerOverlayOpen,
    onOpen: onOpenCreateCustomerOverlay,
    onClose: onCloseCreateCustomerOverlay,
  } = useDisclosure();

  const triggerRefetch = useRecoilValue(triggerRefetchAtom);
  const shouldSignOut = useRecoilValue(shouldSignOutAtom);
  const toast = useToast();
  const [posDevice, setPosDevice] = useRecoilState(posDeviceAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [merchant, setMerchant] = useRecoilState(merchantAtom);
  const currentCartId = useRecoilValue(currentCartIdAtom);
  const setTaxes = useSetRecoilState(taxesAtom);
  const setDiscounts = useSetRecoilState(discountsAtom);
  const setPaymentTypes = useSetRecoilState(paymentTypesAtom);
  const setOnlineReceipts = useSetRecoilState(onlineReceiptsAtom);
  const setOfflineReceipts = useSetRecoilState(offlineReceiptsAtom);
  const setActiveLoyaltyPrograms = useSetRecoilState(activeLoyaltyProgramsAtom);
  const setCustomers = useSetRecoilState(offlineCustomersAtom);
  const [actions, setActions] = useRecoilState(workerActionsAtom);
  const { cart } = useCart();
  const { online } = useNetworkState();

  const { employee, signOut, currentStore } = useAuth({
    applicationName: merchant?.business_name ?? '',
    stage: envVars.STAGE,
  });

  const { isLoading: merchantsLoading, refetch } = useQuery(['get-merchant'], {
    queryFn: async () => {
      const merchant_id =
        currentStore?.merchant_id ?? employee?.merchant_id ?? user?.merchant_id;

      if (!merchant_id) {
        throw new Error('No merchant_id found');
      }

      return shopAndSmileAdapter.getMerchant(merchant_id);
    },
    enabled: !!currentStore || !!employee || !!user,

    onSuccess: (data) => {
      if (data) {
        setMerchant(data);
      } else {
        toast({
          title: 'No Merchant Information on this account',
          description: 'Please contact your administrator',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        // Wait for 9 seconds before signing out
        setTimeout(() => {
          signOut();
        }, 9000);
      }
    },
    onError: (error: any) => {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (shouldSignOut) {
      signOut();
    }
  }, [shouldSignOut]);

  useEffect(() => {
    if (employee && !user) {
      setUser(employee);
      refetch();
    }

    if (currentStore && !store) {
      setStore(currentStore);
    }
  }, [employee, currentStore]);

  useEffect(() => {
    async function populateState() {
      if (store) {
        const taxes = await db.taxes
          .where('merchant_id')
          .equals(store.merchant_id)
          .toArray();
        setTaxes(taxes);

        const discounts = await db.storeDiscounts
          .where('store_id')
          .equals(store.id)
          .toArray();
        setDiscounts(discounts);

        const paymentTypes = await db.paymentTypes
          .where('merchant_id')
          .equals(store.merchant_id)
          .toArray();

        // If the payment type stores array includes the current store id, the include it in the payment types, otherwise, exclude it

        const filteredPaymentTypes = paymentTypes.filter((paymentType) => {
          if (paymentType.stores.includes(store.id)) {
            return paymentType;
          }
        });

        setPaymentTypes(filteredPaymentTypes);

        const onlineReceipts = await db.onlineReceipts
          .where('store_id')
          .equals(store.id)
          .toArray();

        setOnlineReceipts(onlineReceipts);

        const offlineReceipts = await db.offlineReceipts
          .where('store_id')
          .equals(store.id)
          .toArray();
        setOfflineReceipts(offlineReceipts);

        const programs = await db.loyaltyPrograms
          .where('store_id')
          .equals(store.id)
          .and((program) => program.is_active)
          .toArray();
        setActiveLoyaltyPrograms(programs);

        const customers = await db.offlineCustomers
          .where('merchant_id')
          .equals(store.merchant_id)
          .toArray();

        setCustomers(customers);
      }
    }

    populateState();
  }, [store, currentCartId, triggerRefetch, actions]);

  useEffect(() => {
    if (online) {
      setActions([
        WebWorkerActions.SYNC_OFFLINE_CUSTOMERS,
        WebWorkerActions.SYNC_PRODUCTS,
        WebWorkerActions.SYNC_CUSTOMERS,
        WebWorkerActions.SYNC_ONLINE_RECEIPTS,
        WebWorkerActions.SYNC_OFFLINE_RECEIPTS,
        WebWorkerActions.SYNC_DISCOUNTS,
        WebWorkerActions.SYNC_PAYMENT_TYPES,
        WebWorkerActions.SYNC_TAXES,
        WebWorkerActions.SYNC_LOYALTY_PROGRAMS,
      ]);
    }
  }, [online]);

  useEffect(() => {
    if (posDevice) {
      if (
        posDevice.activated === false ||
        posDevice.current_user_id !== user?.id
      ) {
        setPosDevice(null);
      }
    }
  }, [posDevice, user]);

  if (merchantsLoading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <CircularProgress />
      </Flex>
    );
  }

  if (!posDevice) {
    return <SelectPosDevice />;
  }

  return (
    <Flex height="100vh">
      <Box
        height="full"
        width={{ md: '14rem', xl: '18rem' }}
        display={{ base: 'none', lg: 'initial' }}
        overflowY="auto"
        borderRightWidth="1px">
        <Navbar onClose={onCloseNav} />
      </Box>
      <Box bg="white" flex="1" overflowY="auto">
        <ColumnHeader>
          <MainHeader openCart={onOpenCart} openNav={onOpenNav} />
        </ColumnHeader>
        <Outlet />
      </Box>
      <Box
        borderLeftWidth="1px"
        width={{ md: '20rem', xl: '24rem' }}
        display={{ base: 'none', md: 'initial' }}
        overflowY="auto">
        <ColumnHeader>
          <HStack justify="space-between" width="full">
            <HStack spacing="3">
              <ColumnIconButton
                onClick={onOpenNav}
                aria-label="Open Navigation"
                icon={<FiMenu />}
                display={{ md: 'inline-flex', lg: 'none' }}
              />
              <Drawer isOpen={isNavOpen} placement="left" onClose={onCloseNav}>
                <DrawerOverlay />
                <DrawerContent>
                  <Navbar onClose={onCloseNav} />
                </DrawerContent>
              </Drawer>
              <Drawer
                onClose={onCloseCart}
                isOpen={isCartOpen}
                placement="right"
                size={'full'}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <Box height={'full'} width={'full'}>
                    <Sidebar
                      closeCart={onCloseCart}
                      isCartOpen={isCartOpen}
                      openCart={onOpenCart}
                    />
                  </Box>
                </DrawerContent>
              </Drawer>
              <ColumnHeading>CART ITEMS </ColumnHeading>
            </HStack>
            {cart && cart.customer && cart.customer.id ? (
              <ColumnButton
                size={'lg'}
                fontSize={'lg'}
                leftIcon={<FiEdit />}
                onClick={onOpenCustomerOverlay}>
                {cart.customer.name}
              </ColumnButton>
            ) : currentCartId ? (
              <ColumnButton
                size={'lg'}
                fontSize={'lg'}
                leftIcon={<IoMdPersonAdd />}
                onClick={onOpenCustomerOverlay}>
                Add Customer
              </ColumnButton>
            ) : (
              <ColumnButton
                size={'lg'}
                fontSize={'lg'}
                leftIcon={<IoMdPersonAdd />}
                onClick={onOpenCreateCustomerOverlay}>
                Create Customer
              </ColumnButton>
            )}
          </HStack>
        </ColumnHeader>
        <Sidebar
          isCartOpen={isCartOpen}
          openCart={onOpenCart}
          closeCart={onCloseCart}
        />
      </Box>
      <CustomerOverlay
        createCustomer={() => {
          onCloseCustomerOverlay();
          onOpenCreateCustomerOverlay();
        }}
        isSelectable={true}
        isOpen={isCustomerOverlayOpen}
        onClose={onCloseCustomerOverlay}
        placement="top"
      />

      <CreateCustomerOverlay
        isOpen={isCreateCustomerOverlayOpen}
        onClose={onCloseCreateCustomerOverlay}
        placement="bottom"
      />

      <ServiceWorkerUpdatePopup />
    </Flex>
  );
}
