import { Customer, WebWorkerActions } from '@bofrak-backend/shared';
import {
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackDivider,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { shopAndSmileAdapter } from '../../api/backend';
import { useSetRecoilState } from 'recoil';
import { workerActionsAtom } from '../../recoil/atoms';
interface UpdateCustomerOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  customer: Customer;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

interface UpdateCustomerFormValues {
  name?: string;
  email?: string;
  phone_number?: string;
  address?: string;
  city?: string;
  region?: string;
  postal_code?: string;
  country_code?: string;
  customer_code?: string;
  note?: string;
}

const UpdateCustomerOverlay = ({
  isOpen,
  onClose,
  placement = 'top',
  customer,
}: UpdateCustomerOverlayProps) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateCustomerFormValues>({
    defaultValues: {
      name: customer.name,
      email: customer.email,
      phone_number: customer.phone_number,
      address: customer.address,
      city: customer.city,
      region: customer.region,
      postal_code: customer.postal_code,
      country_code: customer.country_code,
      customer_code: customer.customer_code,
      note: customer.note,
    },
  });

  const setWorkerAction = useSetRecoilState(workerActionsAtom);

  const mutation = useMutation(
    (updateDto: UpdateCustomerFormValues & { id: string }) =>
      shopAndSmileAdapter.updateCustomer(updateDto),
    {
      onSuccess: (data: Customer) => {
        toast({
          title: 'Customer Updated',
          description: `Customer ${data.name} updated successfully!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setWorkerAction([WebWorkerActions.SYNC_CUSTOMERS]);

        onClose();
      },
      onError: (error: Error) => {
        toast({
          title: 'Error',
          description: error?.message || 'Failed to update customer.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const onSubmit = (data: UpdateCustomerFormValues) => {
    // Only submit fields that actually have values or changed
    // Here we simply submit all fields, relying on backend handling partial updates
    mutation.mutate({ ...data, id: customer.id });
  };

  return (
    <Drawer size="xl" placement={placement} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <Container py={{ base: '4', md: '8' }}>
            <Stack spacing="5" divider={<StackDivider />}>
              <Stack
                direction="column"
                spacing={{ base: '5', lg: '8' }}
                justify="space-around">
                <Heading textAlign={'center'} as="h2" size="lg">
                  Update Customer
                </Heading>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing="4" px={'20'} width={'auto'}>
                    <FormControl isInvalid={!!errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input
                        {...register('name', {
                          maxLength: {
                            value: 64,
                            message: 'Name must be at most 64 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.email}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        type="email"
                        {...register('email', {
                          maxLength: {
                            value: 100,
                            message: 'Email must be at most 100 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.phone_number}>
                      <FormLabel>Phone Number</FormLabel>
                      <Input
                        {...register('phone_number', {
                          maxLength: {
                            value: 15,
                            message:
                              'Phone number must be at most 15 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.address}>
                      <FormLabel>Address</FormLabel>
                      <Input
                        {...register('address', {
                          maxLength: {
                            value: 192,
                            message: 'Address must be at most 192 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.city}>
                      <FormLabel>City</FormLabel>
                      <Input
                        {...register('city', {
                          maxLength: {
                            value: 64,
                            message: 'City must be at most 64 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.region}>
                      <FormLabel>Region</FormLabel>
                      <Input
                        {...register('region', {
                          maxLength: {
                            value: 64,
                            message: 'Region must be at most 64 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.postal_code}>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        {...register('postal_code', {
                          maxLength: {
                            value: 20,
                            message:
                              'Postal code must be at most 20 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.country_code}>
                      <FormLabel>Country Code</FormLabel>
                      <Input
                        {...register('country_code', {
                          maxLength: {
                            value: 2,
                            message: 'Country code must be 2 characters',
                          },
                          pattern: {
                            value: /^[A-Z]{2}$/i,
                            message:
                              'Country code must be in ISO 3166-1-alpha-2 format',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.customer_code}>
                      <FormLabel>Customer Code</FormLabel>
                      <Input
                        {...register('customer_code', {
                          maxLength: {
                            value: 40,
                            message:
                              'Customer code must be at most 40 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.note}>
                      <FormLabel>Note</FormLabel>
                      <Input
                        {...register('note', {
                          maxLength: {
                            value: 255,
                            message: 'Note must be at most 255 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={mutation.isLoading}>
                      Update
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Stack>
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdateCustomerOverlay;
