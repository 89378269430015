import { Stack } from '@chakra-ui/react';
import { CartItemInterface } from '../../utils/types';
import { CartItem } from './cart-item';

interface CartStackProps {
  isCheckout: boolean;
  cartData: CartItemInterface[];
  updateItemQuantity: (id: string, quantity: number) => void;
  updateItemNote: (id: string, note: string) => void;
  deleteItem: (id: string) => void;
}

const CartStack = (props: CartStackProps) => {
  const {
    cartData,
    updateItemQuantity,
    updateItemNote,
    deleteItem,
    isCheckout,
  } = props;

  return (
    <Stack spacing={3} width={'full'}>
      {cartData.map((item) => {
        return (
          <CartItem
            isCheckout={isCheckout}
            key={item.id}
            {...item}
            is_sold_by_weight={item.is_sold_by_weight}
            onChangeCartQuantity={(quantity) =>
              updateItemQuantity(item.id, quantity)
            }
            onChangeNote={(note) => updateItemNote(item.id, note)}
            onClickDelete={() => deleteItem(item.id)}
          />
        );
      })}
    </Stack>
  );
};

export default CartStack;
