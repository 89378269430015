// useAuth.ts
import { Employee, EncryptCredentialsDto, Store } from '@bofrak-backend/shared';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { apiAdapter } from '../utils/api';

interface UseAuthReturn {
  decryptedCredentials: EncryptCredentialsDto | null;
  encryptedCredentials: string | null;
  errorMessage: string | null;
  isLoading: boolean;
  employee: Employee | null;
  signOut: () => Promise<void>;
  currentStore: Store | null;
  availableStores: Store[];
  requiresStoreSelection: boolean;
  setCurrentStoreById: (storeId: string) => Promise<void>;
}

interface UseAuthParams {
  stage: string;
  applicationName: string;
}

export const useAuth = ({ stage }: UseAuthParams): UseAuthReturn => {
  const [decryptedCredentials, setDecryptedCredentials] =
    useState<EncryptCredentialsDto | null>(null);
  const [encryptedCredentials, setEncryptedCredentials] = useState<
    string | null
  >(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [currentStore, setCurrentStore] = useState<Store | null>(null);
  const [storeId, setStoreId] = useState<string | null>(null);
  const [availableStores, setAvailableStores] = useState<Store[]>([]);

  // Function to handle user sign out
  const signOut = async (): Promise<void> => {
    setIsLoading(true);

    try {
      let accessToken: string | undefined;

      if (decryptedCredentials) {
        accessToken = decryptedCredentials.AccessToken;
      } else if (encryptedCredentials) {
        const decrypted = await apiAdapter.decryptUserData({
          encryptedCredentials,
        });
        accessToken = decrypted.AccessToken;
      } else {
        const storedEncrypted = localStorage.getItem('encryptedCredentials');
        if (storedEncrypted) {
          const decrypted = await apiAdapter.decryptUserData({
            encryptedCredentials: storedEncrypted,
          });
          accessToken = decrypted.AccessToken;
        }
      }

      if (accessToken) {
        await apiAdapter.signOutUser({
          accessToken,
        });
      }
    } catch (error) {
      console.error('Error during sign out:', error);
    }

    // Clear all relevant states and localStorage only if needed
    if (!isEqual(encryptedCredentials, null)) {
      setEncryptedCredentials(null);
    }
    if (!isEqual(decryptedCredentials, null)) {
      setDecryptedCredentials(null);
    }
    if (!isEqual(employee, null)) {
      setEmployee(null);
    }
    if (!isEqual(currentStore, null)) {
      setCurrentStore(null);
    }
    if (!isEqual(errorMessage, null)) {
      setErrorMessage(null);
    }
    localStorage.clear();
    setIsLoading(false);

    // Redirect to the authenticator app with a sign out query param
    const authenticatorUrl = `https://authenticator.${stage}.shopnsmile.org`;
    window.location.href = `${authenticatorUrl}?signOut=true`;
  };

  // Function to set current store by ID
  const setCurrentStoreById = async (
    selectedStoreId: string,
  ): Promise<void> => {
    if (!employee) return;

    try {
      const store = await apiAdapter.getStore(
        selectedStoreId,
        employee.merchant_id,
      );
      if (!isEqual(currentStore, store)) {
        setCurrentStore(store);
      }
      if (!isEqual(storeId, selectedStoreId)) {
        setStoreId(selectedStoreId);
      }
      localStorage.setItem('selectedStoreId', selectedStoreId);
    } catch (error) {
      console.error('Failed to set current store:', error);
      if (!isEqual(errorMessage, 'Failed to select store.')) {
        setErrorMessage('Failed to select store.');
      }
    }
  };

  // Initial authentication and credential fetching
  useEffect(() => {
    const fetchEmployee = async (employee_id: string) => {
      try {
        const fetchedEmployee = await apiAdapter.getUser(employee_id);
        if (!isEqual(employee, fetchedEmployee)) {
          setEmployee(fetchedEmployee);
        }
      } catch (error) {
        console.error('Failed to fetch employee:', error);
        if (!isEqual(errorMessage, 'Failed to fetch employee data.')) {
          setErrorMessage('Failed to fetch employee data.');
        }
      }
    };

    const fetchEncryptedCredentials = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let encrypted = urlParams.get('token')
        ? decodeURIComponent(urlParams.get('token')!)
        : null;

      if (!encrypted) {
        encrypted = localStorage.getItem('encryptedCredentials');
      } else {
        // Clear Local Storage
        localStorage.clear();
        localStorage.setItem('encryptedCredentials', encrypted);
      }

      if (encrypted) {
        if (!isEqual(encryptedCredentials, encrypted)) {
          setEncryptedCredentials(encrypted);
        }
        try {
          const decrypted = await apiAdapter.decryptUserData({
            encryptedCredentials: encrypted,
          });
          if (!isEqual(decryptedCredentials, decrypted)) {
            setDecryptedCredentials(decrypted);
          }

          const { Employee: emp } = decrypted;
          fetchEmployee(emp.id);
        } catch (error) {
          console.error('Error decrypting credentials:', error);
          if (!isEqual(errorMessage, 'Invalid session')) {
            setErrorMessage('Invalid session');
          }
        }
      } else {
        await signOut();
        return;
      }

      const store_id =
        urlParams.get('store_id') || localStorage.getItem('selectedStoreId');
      if (store_id && !isEqual(storeId, store_id)) {
        setStoreId(store_id);
      }

      setIsLoading(false);
    };

    fetchEncryptedCredentials();
  }, []); // Run once on mount

  // Fetch current store or available stores based on employee data
  useEffect(() => {
    const fetchStore = async (store_id: string, merchant_id: string) => {
      try {
        const store = await apiAdapter.getStore(store_id, merchant_id);
        if (!isEqual(currentStore, store)) {
          setCurrentStore(store);
        }
        localStorage.setItem('selectedStoreId', store_id);
      } catch (error) {
        console.error('Failed to fetch store:', error);
        if (!isEqual(errorMessage, 'Failed to fetch store data.')) {
          setErrorMessage('Failed to fetch store data.');
        }
      }
    };

    const fetchAllStores = async (storeIds: string[], merchant_id: string) => {
      try {
        const storesData: Store[] = await Promise.all(
          storeIds.map((id) => apiAdapter.getStore(id, merchant_id)),
        );
        if (!isEqual(availableStores, storesData)) {
          setAvailableStores(storesData);
        }

        if (storesData.length === 1) {
          if (!isEqual(currentStore, storesData[0])) {
            setCurrentStore(storesData[0]);
          }
          if (!isEqual(storeId, storesData[0].id)) {
            setStoreId(storesData[0].id);
          }
          localStorage.setItem('selectedStoreId', storesData[0].id);
        }
      } catch (error) {
        console.error('Failed to fetch stores:', error);
        if (!isEqual(errorMessage, 'Failed to fetch stores.')) {
          setErrorMessage('Failed to fetch stores.');
        }
      }
    };

    if (employee && storeId) {
      fetchStore(storeId, employee.merchant_id);
    }

    if (employee) {
      const { merchant_id, stores } = employee;
      if (stores.length) {
        fetchAllStores(stores, merchant_id);
      } else {
        if (!isEqual(errorMessage, 'No stores available for this employee.')) {
          setErrorMessage('No stores available for this employee.');
        }
      }
    }
  }, [storeId, employee]);

  // Determine if store selection is required
  const requiresStoreSelection: boolean =
    !!employee && availableStores.length > 1 && !currentStore;

  // Memoize the returned object so that its reference only changes when its dependencies change.
  const authValue = useMemo(
    () => ({
      decryptedCredentials,
      encryptedCredentials,
      errorMessage,
      isLoading,
      signOut,
      employee,
      currentStore,
      availableStores,
      requiresStoreSelection,
      setCurrentStoreById,
    }),
    [
      decryptedCredentials,
      encryptedCredentials,
      errorMessage,
      isLoading,
      employee,
      currentStore,
      availableStores,
      requiresStoreSelection,
      setCurrentStoreById,
    ],
  );

  return authValue;
};
