import {
  Customer,
  Discount,
  Employee,
  LoyaltyProgram,
  Merchant,
  PaymentType,
  PosDevice,
  Receipt,
  Store,
  StoreProduct,
  Tax,
  WebWorkerActions,
} from '@bofrak-backend/shared';
import { atom } from 'recoil';
import { CustomerCart, LatestReceipt } from '../utils/types';
import {
  customerCartAtomEffect,
  localPersistEffect,
  posDeviceAtomEffect,
} from './atom-effects';

export const discountsAtom = atom<Discount[]>({
  key: 'discountsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const productsAtom = atom<StoreProduct[]>({
  key: 'productsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const taxesAtom = atom<Tax[]>({
  key: 'taxesAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const activeLoyaltyProgramsAtom = atom<LoyaltyProgram[]>({
  key: 'activeLoyaltyProgramsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

// Whether discounts are allowed globally
export const allowDiscountsState = atom<boolean>({
  key: 'allowDiscountsState',
  default: false,
  effects_UNSTABLE: [localPersistEffect],
});

export const shouldSignOutAtom = atom<boolean>({
  key: 'shouldSignOutAtom',
  default: false,
});

export const searchQueryAtom = atom<string>({
  key: 'searchQueryAtom',
  default: '',
});

export const currentCartIdAtom = atom<string | null>({
  key: 'currentCartIdAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const cartItemsAtom = atom<CustomerCart[]>({
  key: 'cartItemsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect, customerCartAtomEffect],
});

export const userAtom = atom<Employee | null>({
  key: 'userAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const merchantAtom = atom<Merchant | null>({
  key: 'merchantAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const storeAtom = atom<Store | null>({
  key: 'storeAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const posDeviceAtom = atom<PosDevice | null>({
  key: 'posDeviceAtom',
  default: null,
  effects_UNSTABLE: [posDeviceAtomEffect(), localPersistEffect],
});

export const isScreenLockedAtom = atom<boolean>({
  key: 'isScreenLockedAtom',
  default: false,
  effects_UNSTABLE: [localPersistEffect],
});

export const latestReceiptAtom = atom<LatestReceipt | null>({
  key: 'latestReceiptAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const paymentTypesAtom = atom<PaymentType[]>({
  key: 'paymentTypesAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const onlineReceiptsAtom = atom<Receipt[]>({
  key: 'onlineReceiptsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const offlineReceiptsAtom = atom<Receipt[]>({
  key: 'offlineReceiptsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const offlineCustomersAtom = atom<Customer[]>({
  key: 'offlineCustomersAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const triggerRefetchAtom = atom<boolean>({
  key: 'triggerRefetchAtom',
  default: false,
});

// Add this atom to manage the selected receipt
export const selectedReceiptAtom = atom<Receipt | null>({
  key: 'selectedReceiptAtom', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (no receipt selected)
});

export const workerActionsAtom = atom<WebWorkerActions[]>({
  key: 'workerActions',
  default: [
    WebWorkerActions.SYNC_PRODUCTS,
    WebWorkerActions.SYNC_TAXES,
    WebWorkerActions.SYNC_DISCOUNTS,
    WebWorkerActions.SYNC_CUSTOMERS,
    WebWorkerActions.SYNC_OFFLINE_RECEIPTS,
    WebWorkerActions.SYNC_ONLINE_RECEIPTS,
    WebWorkerActions.SYNC_PAYMENT_TYPES,
    WebWorkerActions.SYNC_LOYALTY_PROGRAMS,
  ],
});
