import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { storeAtom } from '../recoil/atoms';
import { EntityTypes, StoreProduct } from '@bofrak-backend/shared';
import { db } from '../api/local';

const useWebSocketStoreProducts = (url: string) => {
  const currentStore = useRecoilValue(storeAtom);

  useEffect(() => {
    if (!currentStore) {
      return;
    }

    const uRL = new URL(url);
    uRL.searchParams.append('entityId', currentStore.id ?? '');
    uRL.searchParams.append('entityType', EntityTypes.STORE);
    const ws = new WebSocket(uRL.toString());

    // Declare a variable to hold the interval ID for the heartbeat
    let heartbeatInterval: ReturnType<typeof setInterval>;

    ws.onopen = () => {
      console.log('WebSocket connection opened.');

      // Start a heartbeat interval (e.g., every 5 minutes).
      heartbeatInterval = setInterval(
        () => {
          if (ws.readyState === WebSocket.OPEN) {
            // Send a heartbeat message. Customize the payload as needed.
            ws.send(
              JSON.stringify({ type: 'heartbeat', timestamp: Date.now() }),
            );
            console.log('Heartbeat sent');
          }
        },
        5 * 60 * 1000,
      ); // 5 minutes interval
    };
    ws.onmessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data) as StoreProduct;

        // Ensure the product belongs to the current store.
        if (currentStore.id !== data.store_id) {
          console.warn(
            'Received product does not match current store. Skipping update.',
          );
          return;
        }

        // Check if the product has a valid deleted_at attribute.
        if (data.deleted_at && !isNaN(new Date(data.deleted_at).getTime())) {
          // If valid, remove the product from IndexedDB.
          db.storeProducts
            .delete([data.store_id, data.id])
            .then(() => {
              console.log(
                `Store product with id ${data.id} removed from IndexedDB.`,
              );
            })
            .catch((error) => {
              console.error(
                'Error removing store product from IndexedDB:',
                error,
              );
            });
        } else {
          // Otherwise, update or insert the product.
          db.storeProducts
            .put(data)
            .then(() => {
              console.log('Store product updated in IndexedDB:', data);
            })
            .catch((error) => {
              console.error(
                'Error updating store product in IndexedDB:',
                error,
              );
            });
        }
      } catch (error) {
        console.error('Error processing WebSocket message:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Clean up the connection and heartbeat interval when the component unmounts.
    return () => {
      clearInterval(heartbeatInterval);
      ws.close();
      console.log('WebSocket connection closed.');
    };
  }, [url, currentStore]);
};

export default useWebSocketStoreProducts;
