import { formatCurrency } from '@bofrak-backend/shared';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  VStack,
  HStack,
  Badge,
  ButtonGroup,
  Button,
  Icon,
  AccordionPanel,
  Stack,
  StackDivider,
  Box,
  Text,
} from '@chakra-ui/react';
import Fuse from 'fuse.js';
import { useMemo } from 'react';
import { MdLocationOn, MdPhone } from 'react-icons/md';
import { useSetRecoilState } from 'recoil';
import { currentCartIdAtom } from '../../recoil/atoms';
import { colors } from '@bofrak-backend/shared';
import { CustomerCart, CartItemInterface } from '../../utils/types';

interface CustomerCartsSummaryProps {
  carts: CustomerCart[];
  deleteCart: (id: string) => void;
  query?: string;
}

export const CustomerCartsSummary = ({
  carts,
  deleteCart,
  query: searchQuery,
}: CustomerCartsSummaryProps) => {
  // Configure Fuse.js options and create a memoized Fuse instance
  const fuse = useMemo(() => {
    // Provide a default empty array if products is undefined
    const data = carts || [];
    return new Fuse(data, {
      keys: ['customer_name', 'store_name', 'customer_number'], // Specify searchable fields
      threshold: 0.2, // Adjust for sensitivity
      ignoreLocation: true,
    });
  }, [carts]);

  // Filter products based on search query
  const filteredCarts = useMemo(() => {
    if (carts) {
      return searchQuery
        ? fuse.search(searchQuery).map((result) => result.item)
        : carts;
    } else {
      return [];
    }
  }, [searchQuery, carts, fuse]);

  return (
    <Accordion allowMultiple>
      {filteredCarts.map((listing, id) => (
        <CartSummary key={id} {...listing} deleteCart={deleteCart} />
      ))}
    </Accordion>
  );
};

export const CartSummary = (
  props: CustomerCart & { deleteCart: (id: string) => void },
) => {
  const { customer, cart_items, store_name, customer_number } = props;
  const setCurrentIdCart = useSetRecoilState(currentCartIdAtom);

  if (!customer) return null;

  const totalMoney = cart_items.reduce(
    (acc, item) => acc + item.gross_total_money * item.line_quantity,
    0,
  );

  return (
    <AccordionItem mt={1} py="1">
      <AccordionButton
        _expanded={{ bg: 'blue.50', color: 'black' }}
        gap={2}
        px="0">
        <VStack width={'full'} align={'start'}>
          <HStack p={'3px'} width={'full'} justify={'space-between'}>
            <Text fontSize={'sm'} fontWeight="semibold">
              {customer.name}
            </Text>
            <Badge variant="pill">{formatCurrency(totalMoney)}</Badge>
            <ButtonGroup size="xs" isAttached>
              <Button
                bg={colors.primary}
                borderRadius={'full'}
                zIndex={10}
                onClick={() => {
                  setCurrentIdCart(props.cart_id);
                }}>
                Select
              </Button>
              <Button
                borderRadius={'full'}
                bg={colors.red}
                zIndex={10}
                onClick={() => {
                  props.deleteCart(props.cart_id);
                }}>
                Delete
              </Button>
            </ButtonGroup>
          </HStack>
          <HStack
            width={'full'}
            justify={'space-between'}
            p="2"
            color="fg.muted">
            {store_name && (
              <HStack>
                <Icon as={MdLocationOn} boxSize="5" />
                <Text as="span">{store_name}</Text>
              </HStack>
            )}

            {customer_number && (
              <HStack>
                <Icon as={MdPhone} boxSize="5" />
                <Text as="span">{customer_number}</Text>
              </HStack>
            )}
          </HStack>
        </VStack>
      </AccordionButton>
      <AccordionPanel px="0">
        <Stack divider={<StackDivider />} spacing="4">
          {cart_items.map((item: CartItemInterface) => (
            <Stack key={item.item_id} fontSize="sm" px="4" spacing="4">
              <Stack direction="row" justify="space-between" spacing="4">
                <HStack spacing="3">
                  <Box>
                    <Text fontWeight="medium" color="fg.emphasized">
                      {item.item_name}
                    </Text>
                    <Text color="fg.muted">
                      {item.line_quantity}{' '}
                      {item.line_quantity > 1 ? 'Items' : 'Item'}
                    </Text>
                  </Box>
                </HStack>
                <Text color="fg.muted">{formatCurrency(item.total_money)}</Text>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};
