/* ServiceWorkerUpdatePopup.tsx */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { colors } from '../utils/constants';

const ServiceWorkerUpdatePopup = () => {
  const toast = useToast();
  const { needRefresh, updateServiceWorker } = useRegisterSW({
    onRegisteredSW: (registration) => {
      toast({
        title: 'Update Successful',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      console.log('Service Worker Registered: ', registration);
    },
    onRegisterError: (error) => {
      toast({
        title: 'Failed, Will Retry Later Automatically',
        description: 'You can also try to refresh the page to update manually',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Service Worker registration error:', error);
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const [shouldRefresh] = needRefresh;
    if (shouldRefresh) {
      setIsOpen(true);
    }
  }, [needRefresh]);

  const onClose = () => setIsOpen(false);
  const handleUpdate = () => {
    // Force the waiting service worker to become active.
    updateServiceWorker(true);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Update Available
          </AlertDialogHeader>
          <AlertDialogBody>
            A new version of this app is available. Would you like to update
            now?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              bg={colors.red}
              size="lg"
              borderRadius={'full'}
              fontSize="md"
              ref={cancelRef}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg={colors.primary}
              size="lg"
              borderRadius={'full'}
              fontSize="md"
              onClick={handleUpdate}
              ml={3}>
              Update
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ServiceWorkerUpdatePopup;
