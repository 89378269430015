import { colors } from '@bofrak-backend/shared';
import { HeadingProps, Text, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { storeAtom } from '../recoil/atoms';

const AppName = (props: HeadingProps) => {
  const store = useRecoilValue(storeAtom);

  return (
    <VStack spacing={0}>
      <Text
        pt={2}
        pb={0}
        fontSize={'lg'}
        textTransform="uppercase"
        fontWeight={'black'}
        letterSpacing="wider"
        whiteSpace="nowrap"
        color={colors.primary}
        {...props}>
        Shop And Smile
      </Text>
      {store && (
        <Text fontWeight={'bold'} fontSize={'sm'} p={0}>
          {store.name}
        </Text>
      )}
    </VStack>
  );
};

export default AppName;
