// useCart.ts
import {
  Customer,
  Discount,
  ReasonMissing,
  ReceiptPayment,
  StoreProduct,
  Tax,
} from '@bofrak-backend/shared';
import { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ulid } from 'ulidx';
import { db } from '../api/local';
import {
  cartItemsAtom,
  currentCartIdAtom,
  discountsAtom,
  storeAtom,
  taxesAtom,
} from '../recoil/atoms';
import { CartItemInterface, CustomerCart } from '../utils/types';
import { computedCartsSelector } from '../recoil/cart.selectors';

export const useCart = () => {
  const [carts, setCarts] = useRecoilState(cartItemsAtom);
  const [currentCartId, setCurrentCartId] = useRecoilState(currentCartIdAtom);
  const store = useRecoilValue(storeAtom);
  const taxes = useRecoilValue(taxesAtom);
  const discounts = useRecoilValue(discountsAtom);
  const computedCarts = useRecoilValue(computedCartsSelector);

  // Use the computed version of the current cart
  const currentCart =
    computedCarts.find((c) => c.cart_id === currentCartId) || null;

  const [change, setChange] = useState(0);

  const numberOfItemsInCart = currentCart
    ? currentCart.cart_items.reduce((acc, item) => acc + item.line_quantity, 0)
    : 0;

  const createNewCart = useCallback(() => {
    const newCartId = ulid();
    const newCart: CustomerCart = {
      cart_id: newCartId,
      store_id: store?.id || '',
      store_name: store?.name || '',
      cart_items: [],
      payments: [],
      discounts: [],
      total_taxes: [],
      customer_name: '',
      customer_number: '',
      total_money: 0,
      gross_total_money: 0,
      additional_discounts: [],
      total_added_taxes: 0,
      total_discounts: 0,
      total_included_taxes: 0,
      points_earned: 0,
      points_deducted: 0,
      points_balance: 0,
    };
    setCarts((prev) => [...prev, newCart]);
    setCurrentCartId(newCartId);
    return newCartId;
  }, [setCarts, setCurrentCartId, store]);

  const addItem = useCallback(
    (product: StoreProduct): void => {
      if (!product.is_available_for_sale || !store) return;

      // If there's no current cart, create one
      const activeCartId = currentCartId || createNewCart();

      setCarts((prevCarts) =>
        prevCarts.map((cart) => {
          if (cart.cart_id !== activeCartId) return cart;

          const existingItemIndex = cart.cart_items.findIndex(
            (item) => item.item_id === product.id,
          );

          if (existingItemIndex !== -1) {
            // Update existing item
            const updatedItems = cart.cart_items.map((item, index) =>
              index === existingItemIndex
                ? {
                    ...item,
                    line_quantity: item.line_quantity + 1,
                    quantity: (item.line_quantity + 1) * item.fraction,
                  }
                : item,
            );
            return { ...cart, cart_items: updatedItems };
          } else {
            // Add new item
            const newItem: CartItemInterface = {
              id: ulid(),
              item_name: product.unit
                ? `${product.name} (${product.unit})`
                : `${product.name} (Unit=${product.quantity_in_unit_package})`,
              price: product.price, // will be computed by selector
              line_quantity: 1,
              original_cost: product.cost,
              original_price: product.price,
              image_url: product.image,
              currency: 'MZN',
              fraction: product.fraction,
              confirmed_quantity: 0,
              quantity: product.fraction,
              is_sold_by_weight: product.is_sold_by_weight,
              line_note: '',
              confirmed_quantity_traceback: [],
              cost: product.cost, // will be recomputed
              cost_total: product.cost, // will be recomputed
              gross_total_money: product.price, // will be recomputed
              is_confirmed: false,
              item_id: product.id,
              line_discounts: [],
              line_taxes: [],
              total_line_added_taxes: 0,
              total_line_included_taxes: 0,
              added_product_taxes: [],
              product_discounts: discounts || [],
              missing: {
                employee_id: '',
                employee_name: '',
                item_id: product.id,
                quantity: 0,
                reason: ReasonMissing.NA,
              },
              missing_quantity_traceback: [],
              sku: product.store_sku,
              total_discount: 0,
              total_money: product.price, // will be recomputed
            };

            // Attach applicable taxes to the product
            let productTaxes: Tax[] = [];
            if (product.taxes && product.taxes.length > 0) {
              if (typeof product.taxes[0] === 'string') {
                productTaxes = taxes
                  ? taxes.filter((tax) =>
                      (product.taxes as string[])
                        .map((x) => x.toLowerCase())
                        .includes(tax.id.toLowerCase()),
                    )
                  : [];
              } else {
                productTaxes = taxes
                  ? taxes.filter((tax) =>
                      (product.taxes as Tax[])
                        .map((t) => t.id.toLowerCase())
                        .includes(tax.id.toLowerCase()),
                    )
                  : [];
              }
            }

            newItem.added_product_taxes = productTaxes;
            return { ...cart, cart_items: [...cart.cart_items, newItem] };
          }
        }),
      );
    },
    [currentCartId, createNewCart, setCarts, discounts, taxes, store],
  );

  const updateItemQuantity = useCallback(
    (itemId: string, quantity: number): void => {
      if (!currentCartId) return;

      setCarts((prevCarts) =>
        prevCarts.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;
          const updatedItems = cart.cart_items.map((item) =>
            item.id === itemId
              ? {
                  ...item,
                  line_quantity: quantity,
                  quantity: quantity * item.fraction,
                }
              : item,
          );
          return { ...cart, cart_items: updatedItems };
        }),
      );

      // Delete empty carts if needed
      deleteEmptyCarts();
    },
    [currentCartId, setCarts],
  );

  const updateItemNote = useCallback(
    (itemId: string, note: string): void => {
      if (!currentCartId) return;
      setCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.cart_id === currentCartId
            ? {
                ...cart,
                cart_items: cart.cart_items.map((item) =>
                  item.id === itemId ? { ...item, line_note: note } : item,
                ),
              }
            : cart,
        ),
      );
    },
    [currentCartId, setCarts],
  );

  const deleteItem = useCallback(
    (itemId: string): void => {
      if (!currentCartId) return;

      setCarts((prevCarts) =>
        prevCarts.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;
          const filteredItems = cart.cart_items.filter(
            (item) => item.id !== itemId,
          );
          return { ...cart, cart_items: filteredItems };
        }),
      );

      // Delete empty carts if needed
      deleteEmptyCarts();
    },
    [currentCartId, setCarts],
  );

  const updateCustomer = useCallback(
    (customer: Customer): void => {
      if (!currentCartId) return;
      setCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.cart_id === currentCartId
            ? {
                ...cart,
                customer_id: customer.id,
                customer,
                customer_name: customer.name ?? '',
                customer_number: customer.phone_number ?? '',
              }
            : cart,
        ),
      );
    },
    [currentCartId, setCarts],
  );

  const deleteCart = useCallback(
    async (cartId: string): Promise<void> => {
      if (!cartId) return;

      // Remove the cart from Recoil state
      setCarts((prevCarts) =>
        prevCarts.filter((cart) => cart.cart_id !== cartId),
      );

      // Remove from IndexedDB if associated with a customer
      const cartToDelete = carts.find((cart) => cart.cart_id === cartId);
      if (cartToDelete && cartToDelete.customer_id) {
        try {
          const compositeKey: [string, string, string] = [
            cartToDelete.customer_id,
            cartToDelete.store_id,
            cartToDelete.cart_id,
          ];
          await db.customerCarts.delete(compositeKey);
        } catch (error) {
          console.error('Failed to delete cart from IndexedDB:', error);
        }
      }

      // If the deleted cart was the current cart, reset currentCartId
      if (cartId === currentCartId) {
        setCurrentCartId(null);
      }
    },
    [carts, currentCartId, setCarts, setCurrentCartId],
  );

  const deleteEmptyCarts = useCallback(() => {
    setCarts((prevCarts) => {
      const updated = prevCarts.filter((cart) => {
        const isEmpty = cart.cart_items.length === 0;
        if (isEmpty && cart.cart_id === currentCartId) {
          setCurrentCartId(null);
        }
        return !isEmpty;
      });
      return updated;
    });
  }, [currentCartId, setCarts, setCurrentCartId]);

  const updatePayment = useCallback(
    (payment: ReceiptPayment): void => {
      if (!store || !currentCartId || !currentCart) return;

      setCarts((prevCarts) =>
        prevCarts.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;

          const existingPaymentIndex = cart.payments.findIndex(
            (p) => p.payment_type_id === payment.payment_type_id,
          );

          // Ensure we do not exceed gross_total_money
          const totalOtherPayments = cart.payments.reduce(
            (acc, p) =>
              acc +
              (p.payment_type_id === payment.payment_type_id
                ? 0
                : p.money_amount),
            0,
          );

          const maxAllowed = currentCart.gross_total_money - totalOtherPayments;
          let adjustedAmount = payment.money_amount;

          if (adjustedAmount > maxAllowed) {
            adjustedAmount = maxAllowed;
            setChange(payment.money_amount - maxAllowed);
          } else {
            setChange(0);
          }

          if (adjustedAmount <= 0) {
            return {
              ...cart,
              payments: cart.payments.filter(
                (p) => p.payment_type_id !== payment.payment_type_id,
              ),
            };
          }

          if (existingPaymentIndex > -1) {
            const updatedPayments = [...cart.payments];
            updatedPayments[existingPaymentIndex] = {
              ...updatedPayments[existingPaymentIndex],
              ...payment,
              money_amount: adjustedAmount,
              updated_at: new Date().toISOString(),
            };
            return { ...cart, payments: updatedPayments };
          } else {
            const newPayment = {
              ...payment,
              money_amount: adjustedAmount,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
            };
            return { ...cart, payments: [...cart.payments, newPayment] };
          }
        }),
      );
    },
    [currentCartId, currentCart, setCarts, store],
  );

  const addDiscountToCartItem = useCallback(
    (cartId: string, itemId: string, discount: Discount): void => {
      setCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.cart_id === cartId
            ? {
                ...cart,
                cart_items: cart.cart_items.map((item) =>
                  item.id === itemId
                    ? {
                        ...item,
                        product_discounts: [
                          ...item.product_discounts,
                          discount,
                        ],
                      }
                    : item,
                ),
              }
            : cart,
        ),
      );
    },
    [setCarts],
  );

  const updateAdditionalDiscounts = useCallback(
    (cartId: string, additional_discounts: Discount[]): void => {
      setCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.cart_id === cartId ? { ...cart, additional_discounts } : cart,
        ),
      );
    },
    [setCarts],
  );

  const removeDiscountFromCartItem = useCallback(
    (cartId: string, itemId: string, discountId: string): void => {
      setCarts((prevCarts) =>
        prevCarts.map((cart) => {
          if (cart.cart_id !== cartId) return cart;
          return {
            ...cart,
            cart_items: cart.cart_items.map((item) =>
              item.id === itemId
                ? {
                    ...item,
                    product_discounts: item.product_discounts.filter(
                      (d) => d.id !== discountId,
                    ),
                  }
                : item,
            ),
          };
        }),
      );
    },
    [setCarts],
  );

  const addTaxToCartItem = useCallback(
    (cartId: string, itemId: string, tax: Tax): void => {
      setCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.cart_id === cartId
            ? {
                ...cart,
                cart_items: cart.cart_items.map((item) =>
                  item.id === itemId
                    ? {
                        ...item,
                        added_product_taxes: [...item.added_product_taxes, tax],
                      }
                    : item,
                ),
              }
            : cart,
        ),
      );
    },
    [setCarts],
  );

  const resetPayments = useCallback(
    (cartId: string): void => {
      setCarts((prevCarts) =>
        prevCarts.map((cart) =>
          cart.cart_id === cartId ? { ...cart, payments: [] } : cart,
        ),
      );
      setChange(0);
    },
    [setCarts],
  );

  return {
    cartData: currentCart ? currentCart.cart_items : [],
    cart: currentCart,
    change,
    numberOfItemsInCart,
    setChange,
    addItem,
    updateItemQuantity,
    updateItemNote,
    deleteItem,
    updateCustomer,
    deleteCart,
    updatePayment,
    addDiscountToCartItem,
    addTaxToCartItem,
    removeDiscountFromCartItem,
    updateAdditionalDiscounts,
    resetPayments,
  };
};
