import {
  colors,
  ConfirmationType,
  formatCurrency,
  getReceiptId,
  PaymentMethodType,
  PaymentPlan,
  ReasonMissing,
  Receipt,
  receiptSchema,
  ReceiptStatus,
  ReceiptType,
  WebWorkerActions,
} from '@bofrak-backend/shared';
import { useQuickPrinter } from '@bofrak-backend/shared-ui';

import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { db } from '../../api/local';
import { useCart } from '../../hooks/use-cart';
import useOnReceiptCreated from '../../hooks/use-on-receipt-created';
import {
  paymentTypesAtom,
  posDeviceAtom,
  storeAtom,
  userAtom,
  workerActionsAtom,
} from '../../recoil/atoms';
import { receiptToQuickPrinterFormat } from '../../utils/functions';
import { PosInventoryChange } from '../../utils/types';

const CartTotals = () => {
  const { cart, deleteCart } = useCart();
  const { printReceipt } = useQuickPrinter();
  const store = useRecoilValue(storeAtom);
  const posDevice = useRecoilValue(posDeviceAtom);
  const { updateLatestReceipt, updateProductInventory, latestReceipt } =
    useOnReceiptCreated();
  const toast = useToast();
  const user = useRecoilValue(userAtom);
  const paymentTypes = useRecoilValue(paymentTypesAtom);
  const setActions = useSetRecoilState(workerActionsAtom);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>({
    amount: 0,
    created_at: new Date().toISOString(),
    frequency: 0,
    merchant_id: store?.merchant_id || '',
    note: '',
    store_id: store?.id || '',
    updated_at: new Date().toISOString(),
  });

  const {
    isOpen: isPaymentPlanModalOpen,
    onOpen: onPaymentPlanModalOpen,
    onClose: onPaymentPlanModalClose,
  } = useDisclosure();

  const subTotal = cart?.total_money || 0;
  const totalDiscount = cart
    ? cart.discounts.reduce((acc, discount) => acc + discount.money_amount, 0)
    : 0;

  const addedTaxes = Math.ceil(cart?.total_added_taxes || 0);
  const includedTaxes = Math.ceil(cart?.total_included_taxes || 0);

  const totalTaxes = Math.ceil(addedTaxes + includedTaxes);
  const receiptTotal = Math.ceil(cart ? cart.gross_total_money : 0);

  const debtPaymentTypes = paymentTypes.filter(
    (paymentType) => paymentType.payment_type === PaymentMethodType.DEBT,
  );

  const debtPayments =
    cart?.payments.filter((payment) =>
      debtPaymentTypes.some(
        (debtPaymentType) => debtPaymentType.id === payment.payment_type_id,
      ),
    ) || [];

  const has_debt = debtPayments.length > 0;

  const debtAmount = debtPayments.reduce(
    (acc, payment) => acc + payment.money_amount,
    0,
  );

  const handleSale = async () => {
    // Save receipt to database

    let receiptToPrint: Receipt | undefined = undefined;
    try {
      if (!cart) {
        toast({
          title: 'Cart is empty',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      if (!user) {
        toast({
          title: 'User not found',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!cart.customer) {
        toast({
          title: 'Please select a customer',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!store) {
        toast({
          title: 'Store not found',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!posDevice) {
        toast({
          title: 'POS Device not found',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      console.log(posDevice);

      if (!posDevice.current_user_id) {
        toast({
          title: 'User not found',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (posDevice.current_user_id !== user.id) {
        toast({
          title:
            'Employee registered on this PosDevice is not the same as the signed in employee',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const totalPayment = Math.ceil(
        cart.payments.reduce((acc, payment) => acc + payment.money_amount, 0),
      );

      if (totalPayment !== receiptTotal) {
        toast({
          title: 'Payment total does not match receipt total',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const threshold = await db.thresholds.get([store.merchant_id, store.id]);

      let confirmation_type = ConfirmationType.HARD;

      if (
        threshold &&
        cart.cart_items.length < threshold.min_quantity &&
        cart.total_money < threshold.min_total_money
      ) {
        confirmation_type = ConfirmationType.SOFT;
      }

      let numericReceiptNumber = 1000;

      if (latestReceipt && latestReceipt.numericReceiptNumber) {
        numericReceiptNumber = latestReceipt.numericReceiptNumber + 1;
      }

      const receiptNumber = getReceiptId({
        posPrefix: posDevice.prefix,
        receiptNumber: numericReceiptNumber,
      });

      const receipt: Receipt = {
        receipt_number: receiptNumber,
        custom_receipt_number: receiptNumber,
        store_id: store.id,
        pos_device_id: posDevice.id,
        customer_id: cart.customer.id,
        customer_updated_traceback: [],
        employee_id: user.id,
        employee_name: user.name,
        pos_device_name: posDevice.name,
        customer_name: cart.customer.name,
        dining_option: 'IN_STORE',
        has_debt,
        failed_checks: [],
        associated_refunds: [],
        client: {
          id: cart.customer?.id || '',
          name: cart.customer?.name || '',
          created_at: cart.customer?.created_at || new Date().toISOString(),
          phone_number: cart.customer?.phone_number || '',
          updated_at: cart.customer?.updated_at || new Date().toISOString(),
          address: cart.customer?.address || '',
          business_name: '',
          email: cart.customer?.email || '',
        },
        comments: [],
        created_at: new Date().toISOString(),
        confirmation_type,
        line_items: cart.cart_items.map((item) => ({
          confirmed_quantity: 0,
          line_quantity: item.line_quantity,
          confirmed_quantity_traceback: [],
          cost: item.cost,
          is_sold_by_weight: item.is_sold_by_weight,
          cost_total: item.cost_total,
          gross_total_money: item.gross_total_money,
          item_id: item.item_id,
          item_name: item.item_name,
          id: item.id,
          is_confirmed: false,
          line_discounts: item.line_discounts,
          line_taxes: item.line_taxes,
          missing_quantity_traceback: [],
          price: item.price,
          quantity: item.quantity,
          fraction: item.fraction,
          sku: item.sku,
          total_money: item.total_money,
          total_discount: item.total_discount,
          line_note: item.line_note,
          missing: {
            quantity: 0,
            reason: ReasonMissing.NA,
            employee_id: user.id,
            employee_name: user.name,
            item_id: item.item_id,
          },
        })),
        payments: cart.payments,
        is_confirmable: false,
        merchant_id: store.merchant_id,
        points_balance: cart.points_balance,
        points_deducted: cart.points_deducted,
        points_earned: cart.points_earned,
        receipt_status: ReceiptStatus.UNCONFIRMED,
        receipt_type: ReceiptType.SALE,
        total_money: receiptTotal,
        updated_at: new Date().toISOString(),
        source: 'POS',
        surcharge: 0,
        threshold: threshold || {
          min_quantity: 0,
          min_total_money: 0,
          store_id: store.id,
          merchant_id: store.merchant_id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          updated_by: [
            {
              id: user.id,
              name: user.name,
            },
          ],
        },
        tip: 0,
        total_discount: totalDiscount,
        total_taxes: cart.total_taxes,
        total_discounts: cart.discounts,
        total_receipt_items: cart.cart_items.length,
        total_tax: totalTaxes,
        customer: cart.customer,
        initial_payment_plan:
          paymentPlan.amount && paymentPlan.frequency ? paymentPlan : undefined,
      };

      receiptSchema.parse(receipt);

      await db.offlineReceipts.put(receipt);

      receiptToPrint = receipt;

      const inventoryChanges: PosInventoryChange[] = receipt.line_items.map(
        (item) => ({
          store_id: store.id,
          inventory_change: -item.quantity,
          product_id: item.item_id,
        }),
      );

      updateLatestReceipt();

      // Update the inventory
      await updateProductInventory(inventoryChanges);

      setActions([
        WebWorkerActions.SYNC_OFFLINE_RECEIPTS,
        WebWorkerActions.SYNC_ONLINE_RECEIPTS,
      ]);

      toast({
        title: 'Receipt Created Successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Clear cart
      deleteCart(cart.cart_id);
    } catch (error) {
      const e = error as Error;

      toast({
        title: e.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      console.error('Error saving receipt:', error);
    }

    // Print receipt
    if (receiptToPrint && store) {
      printReceipt(receiptToQuickPrinterFormat(receiptToPrint, store));
    }
  };

  const gray600 = useColorModeValue('gray.600', 'gray.300');
  const black = useColorModeValue('black', 'white');
  const blue500 = useColorModeValue('blue.500', 'blue.200');

  return (
    <Stack p="5" bg="transparent" mt={'5'} spacing={{ base: '6', md: '10' }}>
      <Stack spacing="4">
        <Stack spacing="2">
          <Stack spacing="1">
            <Stack
              fontWeight={'semibold'}
              direction="row"
              justify="space-between">
              <Text color={gray600}>Subtotal</Text>
              <Text color={black}>{formatCurrency(subTotal)}</Text>
            </Stack>
            {totalTaxes && (
              <Stack
                direction="row"
                fontWeight={'semibold'}
                justify="space-between">
                <Text color={gray600}>Taxes (Added + Included)</Text>
                <Text color={black}>{formatCurrency(totalTaxes)}</Text>
              </Stack>
            )}
            {addedTaxes && (
              <Stack direction="row" justify="space-between">
                <Text color={gray600}> &nbsp; &nbsp; &nbsp; Added </Text>
                <Text color={black}>{formatCurrency(addedTaxes)}</Text>
              </Stack>
            )}
            {includedTaxes && (
              <Stack direction="row" justify="space-between">
                <Text color={gray600}> &nbsp; &nbsp; &nbsp; Included</Text>
                <Text color={black}>{formatCurrency(includedTaxes)}</Text>
              </Stack>
            )}
            {totalDiscount && (
              <Stack direction="row" justify="space-between">
                <Text color={gray600}>Discount</Text>
                <Text color={blue500}>{formatCurrency(-totalDiscount)}</Text>
              </Stack>
            )}
            {cart?.points_deducted && (
              <Stack
                fontWeight={'semibold'}
                direction="row"
                justify="space-between">
                <Text color={gray600}>Customer Points Used</Text>
                <Text color={blue500}>
                  {formatCurrency(cart.points_deducted)}
                </Text>
              </Stack>
            )}
          </Stack>
          <Divider />
          <Stack direction="row" justify="space-between">
            <Text
              fontSize="lg"
              fontWeight="semibold"
              color={useColorModeValue('gray.700', 'gray.200')}>
              Receipt Total
            </Text>
            <Text fontSize="xl" fontWeight="semibold" color={black}>
              {formatCurrency(receiptTotal)}
            </Text>
          </Stack>

          {paymentPlan.amount && paymentPlan.frequency && (
            <Stack direction="row" justify="space-between">
              <Text fontSize="lg" fontWeight="semibold" color={colors.black}>
                Payment Plan
              </Text>
              <Text fontSize="xl" fontWeight="semibold" color={colors.black}>
                {formatCurrency(paymentPlan.amount, true)} /{' '}
                {paymentPlan.frequency} days
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
      <HStack justifyContent={'space-around'}>
        <Button
          leftIcon={
            !has_debt ? (
              <></>
            ) : paymentPlan.amount && paymentPlan.frequency ? (
              <FaPencilAlt />
            ) : (
              <IoMdAdd />
            )
          }
          width="40%"
          size="lg"
          borderRadius={'full'}
          onClick={onPaymentPlanModalOpen}
          bg={colors.black}
          isDisabled={!has_debt}>
          {!has_debt
            ? 'No debt payment'
            : paymentPlan.amount && paymentPlan.frequency
              ? 'Payment Plan'
              : 'Payment Plan'}
        </Button>
        <Button
          borderRadius={'full'}
          onClick={handleSale}
          bg={colors.primary}
          size="lg"
          width="40%"
          fontFamily={'heading'}
          fontSize={'x-large'}
          fontWeight={'bold'}>
          Sale
        </Button>
      </HStack>
      <Modal isOpen={isPaymentPlanModalOpen} onClose={onPaymentPlanModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'} fontWeight={'bold'}>
            Payment Plan for {formatCurrency(debtAmount, true)}{' '}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl padding={'2'}>
              <VStack spacing={'4'}>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '2' }}
                  justify="space-between">
                  <FormLabel variant="inline">
                    <Text fontSize={'lg'} color={colors.black}>
                      Amount
                    </Text>
                  </FormLabel>
                  <NumberInput
                    borderRadius={'full'}
                    defaultValue={paymentPlan?.amount || ''}
                    onChange={(valueString) => {
                      const amount = Math.min(
                        parseInt(valueString),
                        debtAmount,
                      );
                      setPaymentPlan((prev) => ({ ...prev, amount }));
                    }}>
                    <NumberInputField borderRadius={'lg'} />
                  </NumberInput>
                </Stack>

                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '2' }}
                  justify="space-between">
                  <FormLabel variant="inline">
                    <Text fontSize={'lg'} color={colors.black}>
                      Payment Frequency (days)
                    </Text>
                  </FormLabel>
                  <NumberInput
                    borderRadius={'full'}
                    defaultValue={paymentPlan?.frequency || ''}
                    onChange={(valueString) => {
                      const frequency = parseInt(valueString);
                      setPaymentPlan((prev) => ({ ...prev, frequency }));
                    }}>
                    <NumberInputField borderRadius={'lg'} />
                  </NumberInput>
                </Stack>
                <Input
                  borderRadius={'full'}
                  placeholder="Add a note (optional)"
                  value={paymentPlan?.note || ''}
                  onChange={(e) => {
                    const note = e.target.value;
                    setPaymentPlan((prev) => ({ ...prev, note }));
                  }}
                />
                <Button
                  width={'full'}
                  size="lg"
                  borderRadius={'full'}
                  onClick={onPaymentPlanModalClose}
                  bg={colors.black}
                  isDisabled={!has_debt}>
                  Save
                </Button>
              </VStack>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default CartTotals;
