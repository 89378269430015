import { SoftDeleteFlag, Timestamps } from './shared.types';

export interface Customer extends Timestamps {
  id: string; // The customer ID. If included in the POST request, it will cause an update instead of creating a new object.
  name: string; // <= 64 characters. The customer's name.
  email?: string; // <= 100 characters. The customer's email.
  phone_number?: string; // <= 15 characters. The customer's phone number.
  address?: string; // <= 192 characters. The customer's address.
  city?: string; // <= 64 characters. The customer's city, town, or village.
  region?: string; // <= 64 characters. The customer's region (province, state, or prefecture).
  postal_code?: string; // <= 20 characters. The customer's postal code.
  country_code?: string; // The two-letter country code in ISO 3166-1-alpha-2 format.
  customer_code?: string; // <= 40 characters.
  note?: string; // <= 255 characters. Note about the customer.
  first_visit?: string | null; // ISO 8601 date-time string. Default: null. The date of the first customer visit.
  last_visit?: string | null; // ISO 8601 date-time string. Default: null. The date of the most recent customer visit.
  total_visits: number; // Default: 0. The total number of visits.
  total_spent: number; // Default: 0. The total amount the customer has spent.
  total_points: number; // Default: 0. The customer's current points balance.
  permanent_deletion_at?: string | null; // ISO 8601 date-time string. The time when the customer data will be permanently deleted.
  pin?: string; // <= 4 characters. The customer's personal identification number.
  merchant_id: string; // The merchant ID.
  date_of_birth?: string;
}

export interface CreateCustomer {
  name: string; // <= 64 characters.
  email?: string; // <= 100 characters.
  phone_number?: string; // <= 15 characters.
  address?: string; // <= 192 characters.
  city?: string; // <= 64 characters.
  region?: string; // <= 64 characters.
  postal_code?: string; // <= 20 characters.
  country_code?: string; // The two-letter country code in ISO 3166-1-alpha-2 format.
  customer_code?: string; // <= 40 characters.
  note?: string; // <= 255 characters.
  first_visit?: string | null; // ISO 8601 date-time string. Default: null.
  last_visit?: string | null; // ISO 8601 date-time string. Default: null.
  pin: string; // <= 4 characters.
  merchant_id: string; // The merchant ID.
  id?: string; // The customer ID.
}

export interface UpdateCustomer {
  id: string; // The customer ID to update.
  name?: string; // <= 64 characters.
  email?: string; // <= 100 characters.
  phone_number?: string; // <= 15 characters.
  address?: string; // <= 192 characters.
  city?: string; // <= 64 characters.
  region?: string; // <= 64 characters.
  postal_code?: string; // <= 20 characters.
  country_code?: string; // The two-letter country code in ISO 3166-1-alpha-2 format.
  customer_code?: string; // <= 40 characters.
  note?: string; // <= 255 characters.
  first_visit?: string | null; // ISO 8601 date-time string.
  last_visit?: string | null; // ISO 8601 date-time string.
  pin?: string; // <= 4 characters.
}

export interface CustomerPage {
  cursor?: string;
  count?: number;
  customers: Customer[];
}

export interface GetCustomer {
  id: string;
  include_deleted?: SoftDeleteFlag;
}

export interface GetCustomers {
  cursor?: string;
  limit?: number;
  merchant_id: string;
  include_deleted?: SoftDeleteFlag;
}

export interface CustomerDBItem extends Customer {
  PK: string; // CUSTOMER#id
  SK: string; // CUSTOMER#id
  GSI1PK: string; // MERCHANT#merchant_id
  GSI1SK: string; // CUSTOMER#id
  EntityIndexPK: string; // CUSTOMER
  EntityIndexSK: string; // DATE#created_at
}

export enum TransactionType {
  Sale = 'Sale',
  Refund = 'Refund',
}

export interface LoyaltyTransactionItem {
  product_id: string;
  product_name: string;
  price: number;
  quantity: number;
  eligible_for_points: boolean;
}

export interface LoyaltyTransaction {
  id: string;
  customer_id: string;
  type: TransactionType;
  items: LoyaltyTransactionItem[];
  total_amount: number;
  date: string;
  merchant_id: string;
}

export interface LoyaltyProduct {
  id: string;
  name: string;
  price: number;
  eligible_for_points: boolean;
  category?: string;
  store_id: string;
}

export interface UpdateLoyaltyAfterSaleOrRefund {
  customer_id: string;
  total_spent: number;
  visit_count: number;
  merchant_id: string;
  store_id: string;
  points_earned: number;
  points_deducted: number;
}

export interface RedeemPoints {
  merchant_id: string;
  customer_id: string;
  points_to_redeem: number;
  store_id: string;
}

export interface RedeemPointOutput {
  transaction: LoyaltyTransaction;
  discount_amount: number;
}

export enum LoyaltyType {
  MONEY_BASED = 'MONEY_BASED',
  VISIT_BASED = 'VISIT_BASED',
  PERCENTAGE_BASED = 'PERCENTAGE_BASED',
}

export interface LoyaltyProgram extends Timestamps {
  id: string;
  name: string;
  loyalty_type: LoyaltyType;
  description: string;
  points_per_threshold: number;
  threshold_amount: number;
  merchant_id: string;
  store_id: string;
  is_active: boolean;
}

export interface CreateLoyaltyProgram {
  name: string;
  loyalty_type: LoyaltyType;
  description: string;
  points_per_threshold: number;
  threshold_amount: number;
  merchant_id: string;
  is_active: boolean;
  store_id: string;
}

export interface UpdateLoyaltyProgram {
  id: string;
  merchant_id: string;
  store_id: string;
  name?: string;
  loyalty_type?: LoyaltyType;
  description?: string;
  points_per_threshold?: number;
  threshold_amount?: number;
  is_active?: boolean;
}

export interface LoyaltyProgramDBItem extends LoyaltyProgram {
  PK: string; // LOYALTY#id
  SK: string; // LOYALTY#id
  GSI1PK: string; // MERCHANT#merchant_id
  GSI1SK: string; // LOYALTY#id
  GSI2PK: string; // STORE#store_id
  GSI2SK: string; // LOYALTY#id
  EntityIndexPK: string; // LOYALTY
  EntityIndexSK: string; // DATE#created_at
}

export interface LoyaltyProgramPage {
  cursor?: string;
  count?: number;
  programs: LoyaltyProgram[];
}

export interface GetLoyaltyPrograms {
  cursor?: string;
  limit?: number;
  merchant_id: string;
  include_deleted?: SoftDeleteFlag;
}
