import { StoreProduct, colors, formatCurrency } from '@bofrak-backend/shared';
import {
  Alert,
  Badge,
  BoxProps,
  HStack,
  IconButton,
  Input,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';

interface ProductCheckboxCardProps extends BoxProps {
  value: string;
  isInCart: boolean;
  cartId: string;
  quantity: number;
  lineQuantity: number;
  product: StoreProduct;
  updateItemQuantity: (cartId: string, quantity: number) => void;
  addItem: (product: StoreProduct) => void;
  deleteItem: (itemId: string) => void;
  is_available_for_sale: boolean;
  /** Pass this down if you want to display *why* it's not for sale. */
  reason?: string;
}

export const ProductCheckboxCard = (props: ProductCheckboxCardProps) => {
  const {
    cartId,
    quantity,
    lineQuantity,
    product,
    isInCart,
    updateItemQuantity,
    addItem,
    deleteItem,
    is_available_for_sale,
    reason,
    ...rest
  } = props;

  // Store numeric inputs as strings
  const [weightValue, setWeightValue] = useState('');
  const [priceValue, setPriceValue] = useState('');

  useEffect(() => {
    if (product.is_sold_by_weight && isInCart) {
      const w = lineQuantity.toString();
      const p = (lineQuantity * product.price).toString();
      setWeightValue(w);
      setPriceValue(p);
    } else {
      setWeightValue('1');
      setPriceValue(product.price.toString());
    }
  }, [isInCart, product.is_sold_by_weight, lineQuantity, product.price]);

  const handleAddToCart = () => {
    if (isInCart) {
      deleteItem(cartId);
    } else {
      addItem(product);
    }
  };

  const parseInputValue = (val: string) => {
    const parsed = parseFloat(val);
    return isNaN(parsed) ? 0 : parsed;
  };

  return (
    <HStack
      cursor={is_available_for_sale ? 'pointer' : 'not-allowed'}
      borderColor={isInCart ? colors.primary : 'transparent'}
      borderWidth={2}
      borderRadius="2xl"
      shadow="xl"
      p="4"
      width="full"
      {...rest}>
      {/* Left side: Decrement or weight Input if in cart */}
      {isInCart &&
        (product.is_sold_by_weight ? (
          <HStack>
            <Input
              p={1}
              minW={'100px'}
              fontSize={'sm'}
              type="number"
              placeholder="1.5"
              value={weightValue}
              onChange={(e) => {
                const inputVal = e.target.value;
                setWeightValue(inputVal);

                const newWeight = parseInputValue(inputVal);
                const newPrice = newWeight * product.price;
                setPriceValue(newPrice ? newPrice.toString() : '');

                updateItemQuantity(cartId, newWeight);
              }}
            />
            <Text
              pointerEvents="none"
              fontWeight="bold"
              color="gray.900"
              fontSize="sm">
              {product.unit_of_measurement ?? 'KG'}
            </Text>
          </HStack>
        ) : (
          <IconButton
            borderWidth={0}
            color="white"
            bg={colors.primary}
            borderRadius="full"
            padding={0}
            m={1}
            variant="outline"
            aria-label="Subtract"
            onClick={() => {
              if (quantity > 1) {
                updateItemQuantity(cartId, lineQuantity - 1);
              } else {
                deleteItem(cartId);
              }
            }}
            icon={<IoIosRemove size="45" />}
          />
        ))}

      <Spacer />

      {/* Center: Product name, cost/price, & optional error reason */}
      <VStack
        as="label"
        width="full"
        cursor="pointer"
        onClick={
          is_available_for_sale
            ? handleAddToCart
            : () => {
                // Do nothing
              }
        }
        sx={{
          '.focus-visible + [data-focus]': {
            boxShadow: 'outline',
            zIndex: 1,
          },
        }}>
        {product.is_sold_by_weight ? (
          <VStack opacity={is_available_for_sale ? 1 : 0.5}>
            <Text
              textAlign="center"
              width="full"
              color="fg.emphasized"
              fontSize="xs">
              {product.name}
              {product.unit ? ` (${product.unit})` : ''}
            </Text>
            <HStack>
              <Text fontSize="lg" color="fg.emphasized" fontWeight="bold">
                {formatCurrency(product.price)}
              </Text>
              <Badge color="white" bg={colors.green}>
                {formatCurrency(product.cost)}
              </Badge>
            </HStack>
          </VStack>
        ) : (
          <HStack
            opacity={is_available_for_sale ? 1 : 0.5}
            justify="space-between"
            width="full"
            p="1">
            <Text color="fg.emphasized" textOverflow="ellipsis" fontSize="md">
              {product.name}
              {product.unit ? ` (${product.unit})` : ''}
            </Text>
            <VStack>
              {quantity > 0 ? (
                <Text fontSize="lg" color="fg.emphasized" fontWeight="bold">
                  {lineQuantity} &times; {formatCurrency(product.price)}
                </Text>
              ) : (
                <Text fontSize="lg" color="fg.emphasized" fontWeight="bold">
                  {formatCurrency(product.price)}
                </Text>
              )}
              <Badge color="white" bg={colors.green}>
                {formatCurrency(product.cost)}
              </Badge>
            </VStack>
          </HStack>
        )}

        {!is_available_for_sale && reason && (
          <Alert width={'fit-content'} p={1} mt={1} status="error">
            {reason}
          </Alert>
        )}
      </VStack>

      <Spacer />

      {/* Right side: Increment or price Input if in cart */}
      {isInCart &&
        (product.is_sold_by_weight ? (
          <HStack>
            <Text
              pointerEvents="none"
              color="gray.900"
              fontWeight="bold"
              fontSize="sm">
              MT
            </Text>
            <Input
              p={1}
              minW={'100px'}
              type="number"
              fontSize={'sm'}
              placeholder="1234"
              value={priceValue}
              onChange={(e) => {
                const inputVal = e.target.value;
                setPriceValue(inputVal);

                const newPrice = parseInputValue(inputVal);
                const newWeight = newPrice / product.price;
                setWeightValue(newWeight ? newWeight.toFixed(2) : '');

                updateItemQuantity(cartId, newWeight);
              }}
            />
          </HStack>
        ) : (
          <IconButton
            borderWidth={0}
            color="white"
            bg={colors.primary}
            borderRadius="full"
            padding={0}
            m={1}
            variant="outline"
            aria-label="Add"
            onClick={() => updateItemQuantity(cartId, lineQuantity + 1)}
            icon={<IoIosAdd size="45" />}
          />
        ))}
    </HStack>
  );
};
