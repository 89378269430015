import { colors, formatCurrency } from '@bofrak-backend/shared';
import {
  Flex,
  HStack,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import { CartItemInterface } from '../../utils/types';

type CartItemProps = {
  isCheckout?: boolean;
  onClickDelete?: () => void;
  onChangeNote?: (note: string) => void;
  onChangeCartQuantity?: (quantity: number) => void;
  is_sold_by_weight: boolean;
} & CartItemInterface;

export const CartItem = (props: CartItemProps) => {
  const {
    item_name,
    is_sold_by_weight,
    line_note,

    price,
    line_quantity,
    onClickDelete,
    onChangeNote,
    onChangeCartQuantity,
    isCheckout,
    total_money,
  } = props;

  return (
    <Stack
      boxShadow={'md'}
      mb={2}
      borderRadius={isCheckout ? 'lg' : 'xl'}
      padding={1}
      direction="row"
      spacing="1">
      <Stack width="full" spacing="2">
        <Stack
          px={0}
          direction={{ base: 'column', md: 'row' }}
          spacing="3"
          alignItems="flex-start">
          <Text mt={2} fontSize={'xs'} fontWeight={'semibold'}>
            {item_name}
          </Text>

          <VStack>
            <Text fontSize={'xs'} fontWeight={'semibold'}>
              {formatCurrency(total_money, true)}
            </Text>
            <Text fontSize={'xs'} color={'gray.500'}>
              {formatCurrency(price, true)}
            </Text>
          </VStack>
          <HStack>
            <NumberInput
              size="sm"
              m={1}
              value={line_quantity}
              aria-label="Select quantity"
              onChange={(valueString) => {
                const valueNumber = is_sold_by_weight
                  ? parseFloat(valueString) || 0
                  : parseInt(valueString) || 0;
                if (valueNumber > 0) {
                  onChangeCartQuantity?.(valueNumber);
                }
              }}
              maxW={24}
              step={is_sold_by_weight ? 0.1 : 1}
              defaultValue={line_quantity}
              min={is_sold_by_weight ? 0.1 : 1}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </HStack>
        </Stack>
        <HStack>
          <Input
            width={'full'}
            size={'xs'}
            borderWidth={0}
            boxShadow={'inner'}
            borderRadius={'2xl'}
            placeholder="Add a note (optional)"
            value={line_note}
            onChange={(e) => onChangeNote?.(e.target.value)}
            color={useColorModeValue('gray.700', 'gray.300')}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          />
          <IconButton
            size={'xs'}
            variant="outline"
            border={0}
            onClick={onClickDelete}
            color={colors.red}
            aria-label="Send email"
            icon={<MdDelete />}
          />
        </HStack>
        <Flex
          width="full"
          justifyContent="space-between"
          alignItems="center"></Flex>
      </Stack>
    </Stack>
  );
};
