import { colors, Customer, formatCurrency } from '@bofrak-backend/shared';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  HStack,
  IconButton,
  Stack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useLiveQuery } from 'dexie-react-hooks';
import Fuse from 'fuse.js';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { db } from '../../api/local';
import { merchantAtom, searchQueryAtom } from '../../recoil/atoms';
import { SearchInput } from '../search-input';
import UpdateCustomerOverlay from './update-customer-overlay';

interface SearchCustomerProps {
  onSelectCustomer: (customer: Customer) => void;
  createCustomer: () => void;
  isSelectable: boolean;
}

export const SearchCustomer = ({
  onSelectCustomer,
  createCustomer,
  isSelectable,
}: SearchCustomerProps) => {
  const setQuery = useSetRecoilState(searchQueryAtom);

  return (
    <Container py={{ base: '4', md: '8' }} px={{ base: '0', md: 8 }}>
      <Box
        bg="bg.surface"
        boxShadow={{ base: 'none', md: 'sm' }}
        borderRadius={{ base: 'none', md: 'lg' }}>
        <Stack spacing="5">
          <Box px={{ base: '4', md: '6' }} pt="5">
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify="space-between">
              <SearchInput
                setQuery={setQuery}
                placeholder="Search customers..."
              />
              <Button
                onClick={createCustomer}
                borderRadius={'full'}
                leftIcon={<MdAdd />}
                bg={colors.green}
                variant="solid">
                New Customer
              </Button>
            </Stack>
          </Box>
          <Box overflowX="auto">
            <CustomerTable
              isSelectable={isSelectable}
              onSelectCustomer={onSelectCustomer}
            />
          </Box>
          <Box p={{ base: '2', md: '4' }} justifyItems={'center'}>
            <ButtonGroup
              spacing="3"
              justifyContent="space-around"
              width={{ base: 'full', md: 'auto' }}
              variant="secondary">
              <Button
                width={100}
                borderRadius={'full'}
                bg={colors.blue}
                color="white">
                Previous
              </Button>
              <Button
                width={100}
                borderRadius={'full'}
                bg={colors.blue}
                color="white">
                Next
              </Button>
            </ButtonGroup>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

interface CustomerTableProps extends TableProps {
  onSelectCustomer: (customer: Customer) => void;
  isSelectable: boolean;
}

const CustomerTable = ({
  isSelectable,
  onSelectCustomer,
  ...rest
}: CustomerTableProps) => {
  const searchQuery = useRecoilValue(searchQueryAtom);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null,
  );
  const merchant = useRecoilValue(merchantAtom);

  const {
    isOpen: isUpdateCustomerOverlayOpen,
    onClose: onCloseUpdateCustomerOverlay,
    onOpen: onOpenUpdateCustomerOverlay,
  } = useDisclosure();

  // Use useLiveQuery to fetch all customers from the customers table in IndexedDB which belongs to the current merchant
  const customers = useLiveQuery(
    () => db.customers.where('merchant_id').equals(merchant!.id).toArray(),
    [],
  );
  const offlineCustomers = useLiveQuery(
    () =>
      db.offlineCustomers.where('merchant_id').equals(merchant!.id).toArray(),
    [],
  );

  // Configure Fuse.js options and create a memoized Fuse instance
  const fuse = useMemo(() => {
    // Provide a default empty array if customers is undefined
    const data: Customer[] = [];

    if (customers) {
      data.push(...customers);
    }

    if (offlineCustomers) {
      data.push(...offlineCustomers);
    }

    return new Fuse(data, {
      keys: ['name', 'phone_number', 'email', 'customer_code'], // Specify searchable fields
      threshold: 0.3, // Adjust for sensitivity
    });
  }, [customers, offlineCustomers]);

  // Filter customers based on search query
  const filteredCustomers = useMemo(() => {
    const data: Customer[] = [];

    if (customers) {
      data.push(...customers);
    }

    if (offlineCustomers) {
      data.push(...offlineCustomers);
    }

    if (data) {
      return searchQuery
        ? fuse.search(searchQuery).map((result) => result.item)
        : data;
    } else {
      return [];
    }
  }, [searchQuery, customers, fuse, offlineCustomers]);

  // Handle the loading state while customers are being fetched
  if (!customers) {
    return (
      <Box
        maxW="7xl"
        mx="auto"
        px={{ base: '2', md: '4', lg: '6' }}
        py={{ base: '3', md: '4', lg: '6' }}>
        <VStack>
          <CircularProgress isIndeterminate color="blue.500" />
          <Text>Loading Customers...</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <>
      <Table {...rest}>
        <Thead>
          <Tr>
            <Th bg={colors.gray}>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text>Name</Text>
                </HStack>
              </HStack>
            </Th>
            <Th bg={colors.gray}>Phone</Th>
            <Th bg={colors.gray}>Visits</Th>
            <Th bg={colors.gray}>Spent</Th>
            <Th bg={colors.gray}>Credits</Th>
            <Th bg={colors.gray}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredCustomers.map((customer) => (
            <Tr
              key={customer.id}
              cursor={'pointer'}
              onClick={() => {
                if (isSelectable) {
                  onSelectCustomer(customer);
                }
              }}
              _hover={
                isSelectable
                  ? {
                      bg: colors.cyan,
                      borderRadius: 'md',
                    }
                  : {}
              }>
              <Td>
                <HStack spacing="3">
                  <Box>
                    <Text fontWeight="medium">{customer.name}</Text>
                    <Text color="fg.muted">
                      Joined{' '}
                      {moment(customer.created_at).format(
                        'ddd, DD MMM YY @ HH:mm',
                      )}
                    </Text>
                  </Box>
                </HStack>
              </Td>
              <Td>
                <Text color="fg.muted">{customer.phone_number}</Text>
              </Td>
              <Td>
                <Text color="fg.muted">{customer.total_visits}</Text>
              </Td>
              <Td>
                <VStack width={'80%'}>
                  <HStack width={'full'} justify="space-between">
                    <Text fontWeight="medium">Points</Text>
                    <Text color="fg.muted">{customer.total_points}</Text>
                  </HStack>
                  <HStack width={'full'} justify="space-between">
                    <Text fontWeight="medium">Spent</Text>
                    <Text color="fg.muted">
                      {formatCurrency(customer.total_spent)}
                    </Text>
                  </HStack>
                </VStack>
              </Td>
              <Td>
                <VStack width={'80%'}>
                  <HStack width={'full'} justify="space-between">
                    <Text fontWeight="medium">Total</Text>
                    <Text color="fg.muted">{formatCurrency(0)}</Text>
                  </HStack>
                  <HStack width={'full'} justify="space-between">
                    <Text fontWeight="medium">Unpaid</Text>
                    <Text color="fg.muted">{formatCurrency(0)}</Text>
                  </HStack>
                </VStack>
              </Td>
              <Td>
                <IconButton
                  zIndex={10}
                  icon={<FiEdit2 />}
                  variant="tertiary"
                  aria-label="Edit customer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedCustomer(customer);
                    onOpenUpdateCustomerOverlay();
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {selectedCustomer && isUpdateCustomerOverlayOpen && (
        <UpdateCustomerOverlay
          customer={selectedCustomer}
          isOpen={isUpdateCustomerOverlayOpen}
          onClose={onCloseUpdateCustomerOverlay}
          placement="top"
        />
      )}
    </>
  );
};
